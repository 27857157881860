
import { defineComponent, reactive, ref, createVNode } from 'vue';
import { message, Form, Modal } from 'ant-design-vue';
import { ExclamationCircleFilled } from '@ant-design/icons-vue';
import debounce from 'lodash/debounce';
import { not, codeReg } from '@/utils/illuminate';
import { useStore } from 'vuex';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';
import { request } from '@/utils/request';
import { useState } from '@/store';
import router from '@/router';
import { CAPTCHA_TYPE } from '@/utils/constants';
import { useCountdown } from '@/components/fragments/countdown';

export default defineComponent({
  name: 'DepartmentEditorModal',
  emits: ['complete'],
  setup(props, ctx) {
    const state = useState();
    const store = useStore();
    const countdownState = useCountdown();

    const visible = ref(false);
    const defaultFormData = {
      userCode: '',
      userName: undefined,
      code: '',
    };
    const formData = reactive<{
      userCode: any,
      userName: undefined | string,
      code: any,
    }>({
      ...defaultFormData,
    });

    const userData = ref<{
      allState: null;
      department: [];
      jonNumber: null;
      phone: string;
      roleRelId: string;
      userName: string;
    }>({
      allState: null,
      department: [],
      jonNumber: null,
      phone: '',
      roleRelId: '',
      userName: '',
    });

    const resetFields = () => {
      Object.assign(formData, { ...defaultFormData });
    };

    const rules = {
      userName: [{ required: true, message: '请选择接替用户' }],
      code: [
        { required: true, message: '请输入验证码', trigger: 'blur' },
        { pattern: codeReg, message: '验证码错误', trigger: 'blur' },
      ],
    };

    // = ---------------------------- = 用户列表加载 = ---------------------------- =
    const userListLoading = ref(true);
    const userList = ref([]);
    const onUserNameChange = (val: string, option: any) => {
      userListLoading.value = false;
      // 1.启用 2.禁用
      if (option.accountstatus === 2) { // 选择“已禁用”账号时弹出报错窗口
        Modal.warning({
          title: '出错了',
          content: '该员工账号已被禁用，无法移交初始管理员权限。如仍需转移，请先在【企业管理】-【员工】中将该员工账号启用后再执行本操作。',
          icon: createVNode(ExclamationCircleFilled),
          class: 'noneFooterBorder',
          onOk() {
            formData.userName = undefined;
            formData.userCode = '';
          },
        });
      } else {
        formData.userName = val;
        formData.userCode = option.key;
      }
    };


    // 验证手机号码是否重复
    const debouncedLoadUserList = debounce(async (val?: string) => {
      let res: any;
      try {
        const params: Record<string, any> = { pageSize: 20, pageNo: 1, state: 'ON' };
        if (val) {
          params.nameOrPhone = val;
        }
        res = await request.get(createApiUrl('/newlinkSass/enterprise/find-enterprise-user-list'), { params });
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        userListLoading.value = false;
      }

      userList.value = res.data.list;
    }, 1000, { maxWait: 3000 });

    const change = (item: any) => {
      userData.value = item;
      visible.value = true;
      debouncedLoadUserList();
    };

    const codeSending = ref(false);
    const onSendCaptcha = async () => {
      if (countdownState.counting.value || codeSending.value) return;

      codeSending.value = true;
      try {
        await request.get(createApiUrl('/newlinkSass/enterprise/captchar/mobile/send/code'), {
          params: { mobile: userData.value.phone, type: CAPTCHA_TYPE.TRANSFORM_CREATER },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        codeSending.value = false;
      }

      countdownState.start();
      message.success('验证码发送完成');
    };

    // = ---------------------------- = 提交表单 = ---------------------------- =
    const submiting = ref(false);
    const editorFormRef = ref<typeof Form>(null as any);
    const onSubmit = async () => {
      if (submiting.value) return;

      //
      try {
        await editorFormRef.value.validate();
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      }

      submiting.value = true;
      try {
        await request.put(createApiUrl('/newlinkSass/role/change-init-role-for-other-account'), not(formData, ['userName']));
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        submiting.value = false;
      }

      visible.value = false;
      if (state.userInfo.value.mobile === userData.value.phone) {
        message.success('初始管理员转交完成, 请重新登录');
        await store.dispatch('User/logout');
        router.replace({ name: 'auth.login' });
      } else {
        message.success('初始管理员转交完成');
        resetFields();
        await store.dispatch('User/getUserDetail');
        ctx.emit('complete');
      }
    };

    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },

      visible,
      submiting,

      sendCounting: countdownState.counting,
      sendText: countdownState.currentText,
      formData,
      rules,

      userListLoading,
      userList,
      onUserNameChange,
      debouncedLoadUserList,

      userData,
      codeSending,
      onSendCaptcha,

      change,
      resetFields,

      editorFormRef,
      onSubmit,
    };
  },
});
