
import { defineComponent, computed, ref, reactive, onMounted } from 'vue';
import { Form, message } from 'ant-design-vue';
import { useRoute } from 'vue-router';

import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';

import ChangeOwnerModal from './ChangeOwnerModal.vue';

export default defineComponent({
  name: 'OwnerDetailView',
  components: {
    ChangeOwnerModal,
  },
  setup() {
    // @todo 部门显示问题
    const route = useRoute();
    const roleDetail = reactive({
      roleName: '',
    });

    const recordData = reactive<{
      allState: null;
      department: [];
      jonNumber: null;
      phone: string;
      roleRelId: number;
      userName: string;
    }>({
      allState: null,
      department: [],
      jonNumber: null,
      phone: '',
      roleRelId: 0,
      userName: '',
    });

    // 加载数据方法 必须为 Promise 对象
    const loadData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      request.get(createApiUrl('/newlinkSass/role/find-all-role-account'), {
        params: { pageSize: 10, pageNo: 1, roleId: route.params.id },
      })
        .then((res: any) => {
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const tableRef = ref<any>(null as any);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const editorForm = ref<typeof Form>(null as any);

    const changeOwnerRef = ref(null as any);
    const onChangeOwner = (obj: any) => {
      Object.assign(recordData, obj);
      changeOwnerRef.value.change(recordData);
    };

    const title = computed<string>(() => `${roleDetail.roleName} - 人员管理`);

    const onRemove = async (ids: number[]) => {
      //
      let res;
      try {
        res = await request.delete(createApiUrl('/newlinkSass/role/del-role-user-for-list'), {
          data: { ids },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      message.success('人员移除完成');
      onRefreshTable();
    };

    const loadRoleDetail = async () => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/role/find-detail'), { params: route.params });
      } catch (e: Error & any) {
        return message.error(e.message || '');
      }
      Object.assign(roleDetail, res.data);
    };

    onMounted(() => {
      loadRoleDetail();
    });

    return {
      title,
      roleDetail,
      loadRoleDetail,

      labelCol: { span: 11 },
      wrapperCol: { span: 12, offset: 1 },

      tableColumns: [
        { title: '角色名称', dataIndex: 'userName' },
        { title: '部门', slots: { customRender: 'allDepartmentName' }, width: '200px' },
        { title: '手机号码', dataIndex: 'phone' },
        { title: '工号', dataIndex: 'roleRelId' },
        { title: '操作', dataIndex: 'action', width: '250px', slots: { customRender: 'action' }, fixed: 'right' },
      ],

      editorForm,
      recordData,

      changeOwnerRef,
      onChangeOwner,
      onRemove,

      tableRef,
      loadData,
    };
  },
});
