
import { message } from 'ant-design-vue';
import { defineComponent, reactive, ref, getCurrentInstance, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { createApiUrl, sldPageTitle } from '@/utils/utils';
import { request } from '@/utils/request';
import ActionDrawer from './ActionDrawer.vue';
import ChangeOwnerModal from './ChangeOwnerModal.vue';

export default defineComponent({
  name: 'RoleListView',
  components: {
    ActionDrawer,
    ChangeOwnerModal,
  },
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    // 管理员信息
    const recordData = reactive<{
      allState: null;
      department: [];
      jonNumber: null;
      phone: string;
      roleRelId: number;
      userName: string;
    }>({
      allState: null,
      department: [],
      jonNumber: null,
      phone: '',
      roleRelId: 0,
      userName: '',
    });

    const changeOwnerRef = ref(null as any);
    //  打开弹窗并且传递管理员信息
    const onChangeOwner = () => {
      changeOwnerRef.value.change(recordData);
    };

    // 获取管理员的信息
    const loadData = async (id: any) => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/role/find-all-role-account'), {
          params: { pageSize: 10, pageNo: 1, roleId: id },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      Object.assign(recordData, res.data.list[0]);
      onChangeOwner();
    };

    const filterParams = reactive({
      name: '',
    });

    // 加载数据方法 必须为 Promise 对象
    const loadTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      request.get(createApiUrl('/newlinkSass/role/find-all-by-enterprise'), {
        params: { ...filterParams, ...parameter },
      })
        .then((res: any) => {
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const tableRef = ref<any>(null as any);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const onDelItem = async (record: any) => {
      //
      try {
        await request.delete(createApiUrl('/newlinkSass/role/del-role'), {
          data: { roleId: record.roleId },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      message.success('角色删除完成');
      onRefreshTable();
    };

    // = ---------------------------- = 查看 / 新增 / 编辑 = ---------------------------- =
    const actionDrawerRef = ref(null as any);
    const doWith = (action: string, id?: number) => {
      actionDrawerRef.value.todo(action, id);
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_role_authority_page_view', { page_name: '角色权限页_浏览' });
    });

    return {
      filterParams,
      tableColumns: [
        { title: '角色名称', dataIndex: 'roleName' },
        { title: '角色人数', dataIndex: 'num', slots: { customRender: 'num' } },
        { title: '角色说明', dataIndex: 'instruction' },
        { title: '操作', dataIndex: 'action', width: '250px', slots: { customRender: 'action' }, fixed: 'right' },
      ],

      tableRef,
      loadTableData,
      onRefreshTable,
      onDelItem,

      actionDrawerRef,
      doWith,

      loadData,

      changeOwnerRef,
      onChangeOwner,
      sldPageTitle,
    };
  },
});
