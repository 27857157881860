
import { defineComponent, computed, ref, reactive, onMounted, toRaw, getCurrentInstance } from 'vue';
import { useDepartment } from '@/views/main/department/utils';

import Detail from './parts/Detail.vue';
import Editor from './parts/Editor.vue';

export default defineComponent({
  name: 'RoleActionDrawer',
  components: {
    Detail,
    Editor,
  },
  emits: ['complete'],
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const departmentState = useDepartment();

    const visible = ref(false);
    const action = ref('create');
    const recordId = ref(0);

    const title = computed(() => (action.value === 'create'
      ? '添加角色' : action.value === 'edit'
        ? '编辑角色' : '查看角色详情'));

    const todo = (actionStr: string, id: number) => {
      visible.value = true;
      action.value = actionStr;
      recordId.value = id;
      instance?.$sensors.track('es_saas_role_new_popup_view', { page_name: '角色权限_新建角色弹窗_浏览' });
    };

    const onComplete = () => {
      visible.value = false;
      ctx.emit('complete');
    };

    const onCancel = () => {
      // visible.value = false;
      action.value = 'view';
    };

    return {
      ...departmentState,
      labelCol: { span: 4 },
      wrapperCol: { span: 14, offset: 1 },

      visible,
      title,
      action,
      recordId,
      todo,
      onComplete,
      onCancel,
    };
  },
});
