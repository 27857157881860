import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createSlots as _createSlots, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "user_select" }
const _hoisted_2 = {
  key: 0,
  class: "disable"
}
const _hoisted_3 = { class: "changeOwnerModal__codeWrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visible) = $event)),
    class: "changeOwnerModal",
    title: "请验证管理员身份",
    width: "600px",
    "confirm-loading": _ctx.submiting,
    onOk: _ctx.onSubmit,
    onCancel: _ctx.resetFields
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        ref: "editorFormRef",
        model: _ctx.formData,
        rules: _ctx.rules,
        "label-col": _ctx.labelCol,
        "wrapper-col": _ctx.wrapperCol,
        onSubmit: _withModifiers(_ctx.onSubmit, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: "接替人",
            name: "userName"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.formData.userName,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.userName) = $event)),
                placeholder: "请选择转交到的用户",
                style: {"width":"100%"},
                "show-search": "",
                "default-active-first-option": false,
                "show-arrow": false,
                "filter-option": false,
                "not-found-content": _ctx.userListLoading ? undefined : null,
                onChange: _ctx.onUserNameChange,
                onSearch: _ctx.debouncedLoadUserList
              }, _createSlots({
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userList, (d) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: d.userCode,
                      value: d.userName,
                      accountstatus: d.accountStatus
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createElementVNode("span", null, _toDisplayString(d.userName) + " (" + _toDisplayString(d.phone) + ")", 1),
                          (d.accountStatus === 2)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "已禁用"))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["value", "accountstatus"]))
                  }), 128))
                ]),
                _: 2
              }, [
                (_ctx.userListLoading)
                  ? {
                      name: "notFoundContent",
                      fn: _withCtx(() => [
                        _createVNode(_component_a_spin, { size: "small" })
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["value", "not-found-content", "onChange", "onSearch"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "手机号" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.maskMobile(_ctx.userData.phone)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "验证码",
            name: "code"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_a_input, {
                  value: _ctx.formData.code,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.code) = $event)),
                  type: "number",
                  autocomplete: "false",
                  placeholder: "验证码"
                }, null, 8, ["value"]),
                _createVNode(_component_a_button, {
                  type: "primary",
                  ghost: "",
                  disabled: _ctx.codeSending || _ctx.sendCounting,
                  onClick: _ctx.onSendCaptcha
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.sendText), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules", "label-col", "wrapper-col", "onSubmit"])
    ]),
    _: 1
  }, 8, ["visible", "confirm-loading", "onOk", "onCancel"]))
}