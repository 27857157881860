
import { defineComponent, computed, ref, reactive, onMounted } from 'vue';
import { message } from 'ant-design-vue';
import { useRoute, useRouter } from 'vue-router';

import type { TableColumn, TableOptions } from '@/components/Table';
import { createApiUrl } from '@/utils/utils';
import { useState } from '@/store';
import { request } from '@/utils/request';
import AddStaffModal from '@/views/parts/AddStaff.vue';

export default defineComponent({
  name: 'RoleStaffView',
  components: {
    AddStaffModal,
  },
  setup(props, ctx) {
    const route = useRoute();
    const state = useState();
    const filterParam = reactive({
      nameOrPhone: '',
    });
    const roleDetail = reactive({
      roleName: '',
    });
    const isShow = ref(false);

    const tableSelectedRowKeys = ref<any[]>([]);
    const tableSelectedRows = ref<any[]>([]);

    const tableColumns = computed<TableColumn[]>(() => {
      const cols: TableColumn[] = [
        { title: '姓名', dataIndex: 'userName' },
        { title: '部门', slots: { customRender: 'allDepartmentName' }, width: '200px' },
        { title: '手机号', dataIndex: 'phone' },
        { title: '工号', dataIndex: 'jonNumber', customRender: ({ text }) => text || '无' },
      ];
      if (!isShow.value) {
        return [...cols,
          { title: '操作', dataIndex: 'action', width: '180px', slots: { customRender: 'action' }, fixed: 'right' },
        ];
      }

      return cols;
    });

    const title = computed<string>(() => `${roleDetail.roleName} - 人员管理`);

    const onTableSelectChange = (selectedRowKeys: any[], selectedRows: any[]) => {
      tableSelectedRowKeys.value = selectedRowKeys;
      tableSelectedRows.value = selectedRows;
    };

    const tableOptions = computed<TableOptions>(() => ({
      alert: { show: false, clear: () => { tableSelectedRowKeys.value = []; } },
      rowSelection: {
        selectedRowKeys: tableSelectedRowKeys.value,
        onChange: onTableSelectChange,
        getCheckboxProps: (record: any) => ({
          props: { name: 'check-line' },
        }),
      },
    }));

    // 加载数据方法 必须为 Promise 对象
    const loadTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      const params: any = {
        ...parameter,
        roleId: +route.params.id,
      };

      if (filterParam.nameOrPhone) {
        params.nameOrPhone = filterParam.nameOrPhone;
      }

      request.get(createApiUrl('/newlinkSass/role/find-all-role-account'), { params })
        .then((res: any) => {
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const tableRef = ref<any>(null as any);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const loadRoleDetail = async () => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/role/find-detail'), { params: route.params });
      } catch (e: Error & any) {
        return message.error(e.message || '');
      }

      if (res.data.roleName === '员工') {
        isShow.value = true;
      }
      Object.assign(roleDetail, res.data);
    };

    const addItemRef = ref<any>(null as any);
    const onAddStaff = () => {
      addItemRef.value.add();
    };

    const onRemove = async (ids: number[]) => {
      //
      let res;
      try {
        res = await request.delete(createApiUrl('/newlinkSass/role/del-role-user-for-list'), {
          data: { ids },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      message.success('人员移除完成');
      onRefreshTable();
    };

    const onRemoveMuti = () => {
      if (tableSelectedRows.value.length === 0) {
        return message.error('请选择要删除的人员');
      }

      onRemove(tableSelectedRowKeys.value);
    };

    const onLoadStaffList = async (departmentId: string) => {
      const res = await request.get(
        createApiUrl('/newlinkSass/role/unbind/staff/list'),
        {
          params: { roleId: route.params.id, departmentId },
          timeout: 30000,
        },
      );
      return res.data.adminRoleDtos;
    };

    const onSubmitStaffList = (userCodes: string[]) => request.put(
      createApiUrl('/newlinkSass/role/add-role-user-for-list'),
      { roleId: route.params.id, userCode: userCodes },
    );

    onMounted(() => {
      //
      loadRoleDetail();
    });

    return {
      title,
      filterParam,
      roleDetail,

      tableSelectedRowKeys,
      tableSelectedRows,
      tableOptions,
      tableColumns,

      tableRef,
      loadTableData,
      onRefreshTable,
      loadRoleDetail,

      addItemRef,
      onAddStaff,
      onRemoveMuti,
      onRemove,

      isShow,

      onLoadStaffList,
      onSubmitStaffList,
    };
  },
});
