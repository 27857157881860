import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "actionDrawer__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_detail = _resolveComponent("detail")!
  const _component_editor = _resolveComponent("editor")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createBlock(_component_a_drawer, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    class: "actionDrawer",
    width: "600",
    title: _ctx.title,
    "destroy-on-close": true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.action === 'view')
          ? (_openBlock(), _createBlock(_component_detail, {
              key: 0,
              "record-id": _ctx.recordId,
              onRevampData: _ctx.todo
            }, null, 8, ["record-id", "onRevampData"]))
          : (_openBlock(), _createBlock(_component_editor, {
              key: 1,
              action: _ctx.action,
              "record-id": _ctx.recordId,
              onComplete: _ctx.onComplete,
              onCancel: _ctx.onCancel
            }, null, 8, ["action", "record-id", "onComplete", "onCancel"]))
      ])
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}