
import { defineComponent, computed, ref, reactive, onMounted, toRaw, watch } from 'vue';
import { Form, message } from 'ant-design-vue';
import { integer, string } from 'vue-types';
import { deepReduce, settleTree } from '@/utils/illuminate';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';
import { useDepartment } from '@/views/main/department/utils';
import { request } from '@/utils/request';
import { getOpenKeyList, resetKey } from '@/views/role/utils';
import { useState } from '@/store';

const defaultFormData = {
  roleName: '',
  instruction: '',
  menus: [],
  departmentState: 'ALL',
  departmentRels: [],
};

export default defineComponent({
  name: 'RoleEditView',
  props: {
    action: string().isRequired,
    recordId: integer(),
  },
  emits: ['complete', 'cancel'],
  setup(props, ctx) {
    const submiting = ref(false);
    const state = useState();
    const departmentState = useDepartment();

    const isEdit = computed<boolean>(() => props.action === 'edit');

    const formData = reactive<{
      roleName: string;
      instruction: string;
      menus: string[];
      departmentState: string;
      departmentRels: any[];
    }>({
      ...defaultFormData,
    });

    const rules = ref<Record<string, any>>({
      roleName: [{ required: true, message: '请输入角色名', trigger: 'change' }],
      instruction: [{ required: true, message: '请输入角色介绍', trigger: 'change' }],
      menus: [{ required: true, type: 'array', message: '请选择角色菜单权限', trigger: 'change' }],
      departmentRels: [{
        validator: (rule: any, value: any) => (formData.departmentState === 'OTHER' && !value.length
          ? Promise.reject('请选择部门') : Promise.resolve()),
      }],
    });

    const menuTree = ref<any[]>([]);

    // 加载菜单数据
    const loadMenuList = async () => {
      let res;
      try {
        res = await request.get(createApiUrl('/newlinkSass/role/find-role-menu-interface'));
      } catch (e: Error & any) {
        return message.error(e.message || '');
      }

      let tree = settleTree(res.data, {
        title: 'menuName',
        name: 'menuName',
        value: 'menuId',
        key: 'menuId',
      }, 'list');

      tree = resetKey(tree);

      menuTree.value = tree;
    };

    const editorForm = ref<typeof Form>(null as any);

    let orginData: any;

    // 加载数据
    const loadData = async () => {
      let resData: any;
      try {
        ({ data: resData } = await request.get(createApiUrl('/newlinkSass/role/find-detail'), { params: { id: props.recordId } }));
      } catch (e: Error & any) {
        return message.error(e.message || '');
      }

      orginData = resData;
      const tree = settleTree(resData.menus, {
        title: 'menuName',
        name: 'menuName',
        value: 'menuId',
        key: 'menuId',
      });

      menuTree.value = resetKey(tree);
      Object.assign(formData, resData);
      formData.menus = getOpenKeyList(tree);
      if (resData.departmentRes) {
        formData.departmentRels = (resData.departmentRes.map((item: any) => item.departmentId));
      }
    };

    const onSubmit = async () => {
      //
      try {
        await editorForm.value.validate();
      } catch (e: Error & any) {
        return message.error(firstError(e));
      }

      const data: any = {
        ...formData,
        departmentRels: deepReduce<any[]>(toRaw(departmentState.departmentTree.value), 'children', (prev, curr) => {
          if (formData.departmentRels.includes(curr.value)) prev.push(curr);
          return prev;
        }, []),
        menus: menuTree.value,
      };

      if (isEdit.value && orginData.departmentRes) {
        data.delDepartment = orginData.departmentRes.map((item: any) => item.relId);
      }

      submiting.value = true;

      console.log(data, 'data');
      try {
        await request.put(createApiUrl('/newlinkSass/role/save-or-update'), data);
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      } finally {
        submiting.value = false;
      }

      message.success(isEdit.value ? '角色编辑完成' : '角色添加完成');
      ctx.emit('complete');
    };

    const onCancel = () => {
      ctx.emit('cancel');
    };

    // 点击父节点
    const onCheckAllChange = async (obj: any) => {
      const arr: any = menuTree.value;
      const newArr: any = [];
      obj.state = obj.state === 'OPEN' ? 'CLOSE' : 'OPEN';
      // 替换子集下全部状态
      obj.list.map((item: any) => {
        item.state = obj.state;
      });

      // 替换原有的父节点
      arr.map((item: any) => {
        if (item.menuId !== obj.menuId) newArr.push(item);
        else newArr.push(obj);
      });

      menuTree.value = newArr;
      formData.menus = getOpenKeyList(newArr);
    };

    // 点击子节点
    const onChangeChild = async (group: any) => {
      const arr: any = menuTree.value;
      const newArr: any = [];
      let state: any = false;
      // 查询全部的子节点状态并赋予父节点
      group.list.map((item: any) => {
        if (item.state === 'OPEN') {
          state = true;
          return state;
        }
      });

      group.state = state ? 'OPEN' : 'CLOSE';

      // 替换原有的父节点
      arr.map((item: any) => {
        if (item.menuId !== group.menuId) newArr.push(item);
        else newArr.push(group);
      });

      menuTree.value = newArr;
      formData.menus = getOpenKeyList(newArr);
    };

    onMounted(() => {
      if (isEdit.value) {
        loadData();
      } else {
        loadMenuList();
      }

      departmentState.loadDepartmentTree();
    });

    return {
      ...departmentState,

      labelCol: { span: 4 },
      wrapperCol: { span: 14, offset: 1 },

      startTimeOptions: [
        { label: '立即生效', value: 1 },
        { label: '自定义开始日期', value: 2 },
      ],
      endTimeOptions: [
        { label: '立即生效', value: 1 },
        { label: '自定义结束日期', value: 2 },
      ],
      limitOptions: [
        { label: '不限', value: 0 },
        { label: '限制金额', value: 1 },
      ],
      departmentOptions: [
        { label: '所有部门', value: 'ALL' },
        { label: '所在部门及子部门', value: 'THIS' },
        { label: '指定部门', value: 'OTHER' },
        { label: '无', value: 'NULL' },
      ],
      projcectOptions: [
        { label: '所有项目', value: 0 },
        { label: '指定项目', value: 1 },
        { label: '无', value: 2 },
      ],

      expandedKeys: ref([]),

      editorForm,
      formData,
      rules,
      menuTree,
      loadMenuList,
      submiting,
      onSubmit,
      onCancel,

      onCheckAllChange,
      onChangeChild,
    };
  },
});
