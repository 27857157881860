
import { defineComponent, computed, ref, reactive, onMounted, watch, onBeforeUnmount } from 'vue';
import { Form, message } from 'ant-design-vue';
import { integer } from 'vue-types';
import { settleTree } from '@/utils/illuminate';

import { FormOutlined } from '@ant-design/icons-vue';
import { createApiUrl } from '@/utils/utils';
import { useDepartment } from '@/views/main/department/utils';
import { request } from '@/utils/request';
import { resetKey } from '@/views/role/utils';

const defaultData = {
  roleName: '',
  instruction: '',
  menus: [],
  departmentState: 'ALL',
  departmentRels: [],
};

export default defineComponent({
  name: 'RoleDetailDrawer',
  components: {
    FormOutlined,
  },
  props: {
    recordId: integer().isRequired,
  },
  emits: ['revampData'],
  setup(props, ctx) {
    const departmentState = useDepartment();
    const recordData = reactive<{
      roleName: string;
      instruction: string;
      menus: string[];
      departmentState: string;
      departmentRels: any[];
    }>({
      ...defaultData,
    });

    const menuTree = ref<any[]>([]);

    // 加载数据
    const loadData = async () => {
      let res;
      try {
        res = await request.get(createApiUrl('/newlinkSass/role/find-detail'), { params: { id: props.recordId } });
      } catch (e: Error & any) {
        return message.error(e.message || '');
      }

      const tree = settleTree(res.data.menus, {
        title: 'menuName',
        name: 'menuName',
        value: 'menuId',
        key: 'menuId',
      }, 'list');

      menuTree.value = resetKey(tree);

      Object.assign(recordData, res.data);
    };

    const editorForm = ref<typeof Form>(null as any);
    const departmentMap = {
      ALL: '允许管理 所有部门 的单据、员工',
      THIS: '允许管理 所在部门及子部门 的单据、员工',
      OTHER: '允许管理 以下部门 的单据、员工',
      NULL: '不允许管理任何部门数据',
    };

    const revampData = () => {
      ctx.emit('revampData', 'edit', props.recordId);
    };

    onMounted(() => {
      departmentState.loadDepartmentTree();
      loadData();
    });

    return {
      ...departmentState,
      labelCol: { span: 4 },
      wrapperCol: { span: 14, offset: 1 },

      departmentMap,
      editorForm,
      recordData,
      menuTree,

      revampData,
    };
  },
});
